import { render, staticRenderFns } from "./RedressPlate.vue?vue&type=template&id=971387e8&scoped=true&"
import script from "./RedressPlate.vue?vue&type=script&lang=js&"
export * from "./RedressPlate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "971387e8",
  null
  
)

export default component.exports