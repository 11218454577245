<template>
  <a-modal
      title="确认弹框"
      :maskClosable="false"
      :visible="visible"
      :confirm-loading="loading"
      okText="确定"
      cancelText="取消"
      @ok="confirmOk"
      @cancel="onCancel"
  >
    <div style="text-align: center">
      <h2 v-if="channelInfo">{{ channelInfo.plate }}</h2>
    </div>
    <a-descriptions v-if="channelInfo">
      <a-descriptions-item label="停车场" v-if="channelInfo.parkingInfo">
        {{ channelInfo.parkingInfo.parkingName }}
      </a-descriptions-item>
      <a-descriptions-item label="通行方向">
        {{ channelInfo.direction === 1 ? '入场' : '出场' }}
      </a-descriptions-item>
      <a-descriptions-item label="通行区域">
        {{ channelInfo.areaName }}
      </a-descriptions-item>
    </a-descriptions>
    <a-form-model
        ref="ruleForm"
        :model="form"
        :rules	="rules"
        :label-col="{span: 4 }"
        :wrapper-col=" { span: 18 }">
      <a-form-model-item label="放行方式" ref="releaseType" prop="releaseType">
        <a-radio-group v-model="form.releaseType">
          <a-radio-button :value="0">计入现金</a-radio-button>
          <a-radio-button :value="1">特殊放行</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <ReasonChoose v-model="form.remark" />
      <a-form-model-item label="输入备注" ref="remark" prop="remark">
        <a-input v-model="form.remark" placeholder="输入或选择备注"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {confirmDialog} from "../../api/opration";
import {mapGetters} from "vuex";
import ReasonChoose from "./ReasonChoose";

export default {
  name: "ConfirmWindow",
  components: {ReasonChoose},
  props: {
    channelInfo: {
      type: Object,
      defaultValue: () => {}
    },
    currentParkingId: {
      type: String,
      defaultValue: () => undefined
    },
    visible: {
      type: Boolean,
      defaultValue: () => false
    }
  },
  computed: {
    ...mapGetters(['currentAnswer'])
  },
  data () {
    return {
      loading: false,
      form: {
        releaseType: undefined,
        remark: undefined
      },
      rules: {
        releaseType: { required: true, message: '放行方式不能为空'},
      }
    }
  },
  methods: {
    confirmOk () {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) {
          return false;
        }
        this.form.parkingId = this.currentParkingId
        this.form.channelId = this.channelInfo.channelId
        this.form.requestId = this.requestId
        if (this.currentAnswer) {
          this.form.callId = this.currentAnswer.id
        }
        this.loading = true
        confirmDialog({ ...this.form, requestId: this.channelInfo.passRequestId }).then(() => {
          this.$message.success('确认放行操作成功');
          this.$emit('loadLocalChannelInfo')
          this.onCancel()
        }).catch(e => {
          this.$message.error('操作确认放行操作失败 ' + e.message)
        }).finally(() => {
          this.loading = false
        })
      })
    },
    onCancel () {
      this.$emit('cancel')
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>

<style scoped>

</style>
