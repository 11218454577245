<template>
  <a-modal
      title="补录入场"
      :maskClosable="false"
      :visible="visible"
      :confirm-loading="loading"
      okText="确定"
      cancelText="取消"
      @ok="onOk"
      @cancel="onCancel"
  >
    <a-form-model
        layout="horizontal"
        ref="form"
        :model="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :rules="rules"
    >
      <a-form-model-item label="停车场"  v-if="channelInfo && channelInfo.parkingInfo">
        {{ channelInfo.parkingInfo.parkingName }}
      </a-form-model-item>
      <a-form-model-item label="入场车牌" ref="enterPlate" prop="enterPlate">
        <PlateNumberInput :plate="form.enterPlate" v-model.trim="form.enterPlate" placeholder="请输入弹框的车牌" />
      </a-form-model-item>
      <a-form-model-item label="入场通道" ref="enterChannelId" prop="enterChannelId">
        <a-tree-select
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            placeholder="请选择放行的通道"
            tree-default-expand-all
            v-if="localChannelTree"
            :tree-data="localChannelTree"
            v-model="form.enterChannelId"
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="入场车类型"  ref="enterCarTypeId" prop="enterCarTypeId">
        <a-radio-group v-model="form.enterCarTypeId" v-if="channelInfo && channelInfo.localAttr && channelInfo.localAttr.carTypes">
          <a-radio-button v-for="item in channelInfo.localAttr.carTypes" :key="item.carTypeId" :value="item.carTypeId">
            {{item.carTypeName}}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="入场时间">
        <a-date-picker
            format="YYYY-MM-DD HH:mm:ss"
            :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
            v-model="form.enterTime"
        >
          <template slot="renderExtraFooter">
            <a-button size="small" @click="onClickQuickTime(1, 'h')">1小时前</a-button>
            <a-button size="small" @click="onClickQuickTime(2, 'h')">2小时前</a-button>
            <a-button size="small" @click="onClickQuickTime(5, 'h')">5小时前</a-button>
            <a-button size="small" @click="onClickQuickTime(12, 'h')">12小时前</a-button>
            <a-button size="small" @click="onClickQuickTime(1, 'd')">1天前</a-button>
            <a-button size="small" @click="onClickQuickTime(2, 'd')">2天前</a-button>
          </template>
        </a-date-picker>
      </a-form-model-item>
      <ReasonChoose v-model="form.remark"/>
      <a-form-model-item label="输入备注">
        <a-input v-model="form.remark" placeholder="输入或选择备注"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import PlateNumberInput from "../commons/PlateNumberInput";
import moment from "moment";
import {mapGetters} from "vuex";
import {addEnterInfo} from "../../api/opration";
import ReasonChoose from "./ReasonChoose";
export default {
  name: "AddEnter",
  components: {ReasonChoose, PlateNumberInput},
  props: {
    visible: {
      type: Boolean,
      defaultValue: false
    },
    currentParkingId: {
      type: String,
      defaultValue: undefined
    },
    channelInfo: {
      type: Object,
      defaultValue: () => {}
    },
    localChannelTree: {
      type: Array,
      defaultValue: () => []
    }
  },
  data () {
    return {
      loading: false,
      form: {
        enterPlate: undefined,
        enterTime: undefined,
        enterChannelId: undefined,
        enterCarTypeId: undefined,
        remark: undefined
      },
      rules: {
        enterPlate: { required: true, message: '入场车牌必须输入' },
        enterChannelId: { required: true, message: '入场通道必须选择' },
        enterCarTypeId: { required: true, message: '入场车类型必须选择' }
      }
    }
  },
  computed: {
    ...mapGetters(['currentAnswer'])
  },
  methods: {
    moment,
    onOk () {
      this.$refs.form.validate(valid => {
        console.info(valid)
        if (!valid) {
          return false;
        }
        const params = {
          parkingId: this.currentParkingId,
          channelId: this.channelInfo.channelId,
          enterPlate: this.form.enterPlate,
          enterChannelId: this.form.enterChannelId,
          enterCarTypeId: this.form.enterCarTypeId,
          remark: this.form.remark
        }
        if (this.form.enterTime) {
          params.enterTime = this.form.enterTime.format('yyyy-MM-DD HH:mm:ss')
        }
        if (this.currentAnswer) {
          params.callId = this.currentAnswer.id;
        }
        this.loading = true
        addEnterInfo(params)
            .then(() => {
              this.$message.success('补录入场记录成功')
              this.onCancel()
              setTimeout(() => {
                this.$emit('loadLocalChannelInfo')
              },1000)
            })
            .catch(e => this.$message.error('补录入场记录失败' + e.message))
            .finally(() => this.loading = false)
      });
    },
    onCancel () {
      this.$refs.form.resetFields()
      this.form.enterTime = undefined
      this.remark = undefined
      this.$emit('cancel')
    },
    onClickQuickTime( value, unit) {
      this.form.enterTime = moment().subtract(value, unit)
    }
  }
}
</script>

<style scoped>

</style>
